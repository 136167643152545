import { createSignal } from "solid-js";
import { useNavigate } from "@solidjs/router";
import "./TestForm2.scss";

const TestForm2 = () => {
  const [email, setEmail] = createSignal("");
  const [firstName, setFirstName] = createSignal("");
  const [lastName, setLastName] = createSignal("");
  const [phoneNumber, setPhoneNumber] = createSignal("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    // navigate(`/lead_gen_page_2`);
    e.preventDefault();
    window.location.href = '/lead_gen_page_2';
  };

  return (
    <div className="TestForm2">
      <h1>Case 2: Conversion page has non-user's email address</h1>
      <p>{"The conversion page (redirect page after form submission) will contain an email, but it won't be a user's email."}</p>
      <p>{"Auto PII will pick up the \"wrong\" email from the conversion page (since it is not the user's email)."}</p>
      <p>Soft login will override this behavior, preserving the email entered in the form below.</p>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email()}
            onInput={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        {/* <div>
          <label>First Name:</label>
          <input
            type="text"
            value={firstName()}
            onInput={(e) => setFirstName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Last Name:</label>
          <input
            type="text"
            value={lastName()}
            onInput={(e) => setLastName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Phone Number:</label>
          <input
            type="tel"
            value={phoneNumber()}
            onInput={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div> */}
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default TestForm2;
