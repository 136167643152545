import { createSignal } from "solid-js";
import "./CustomConversion.scss";

const CustomConversion = () => {
  const [email, setEmail] = createSignal("");
  const [firstName, setFirstName] = createSignal("");
  const [lastName, setLastName] = createSignal("");
  const [phoneNumber, setPhoneNumber] = createSignal("");

  const [conversionTag, setConversionTag] = createSignal(
    "-tyzCKXJ0NEZEPWwkYQ-"
  );

  return (
    <div className="CustomConversion">
      <h1>Custom conversion with no email address present on page</h1>
      <p>Click the button below to fire a conversion ping.</p>
      <p>There's no email here for Auto PII to pick up.</p>
      <p>
        If soft login is enabled and you have filled out the form at least once
        in the past 90 days,
      </p>
      <p>this conversion will fire with the previously saved email</p>
      {/* <form
        onSubmit={(e) => {
          e.preventDefault();
          gtag("set", "user_data", {
            email: email(),
            phone_number: phoneNumber(),
            address: {
              first_name: firstName(),
              last_name: lastName(),
              street: "",
              city: "",
              region: "",
              postal_code: "",
              country: "",
            },
          });
          alert(
            `User Data Stored!\nEmail: ${email()}\nPhone Number: ${phoneNumber()}\nFirst Name: ${firstName()}\nLast Name: ${lastName()}`
          );
        }}
      >
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email()}
            onInput={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>First Name:</label>
          <input
            type="text"
            value={firstName()}
            onInput={(e) => setFirstName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Last Name:</label>
          <input
            type="text"
            value={lastName()}
            onInput={(e) => setLastName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Phone Number:</label>
          <input
            type="tel"
            value={phoneNumber()}
            onInput={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>
        <button type="submit">Save</button>
      </form> */}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          gtag("event", "conversion", {
            send_to: `AW-16651671669/${conversionTag()}`,
          });
        }}
      >
        <div>
          <label>Conversion Label:</label>
          <input
            type="text"
            value={conversionTag()}
            onInput={(e) => setConversionTag(e.target.value)}
            required
          />
        </div>
        <button type="submit">Convert</button>
      </form>
    </div>
  );
};

export default CustomConversion;
