import { render } from "solid-js/web";
import { Router, Route, Routes, A, useLocation } from "@solidjs/router";
import { createSignal, createEffect } from "solid-js";

import TestForm1 from "./pages/test_form_1/TestForm1.js";
import TestForm2 from "./pages/test_form_2/TestForm2.js";
import TestForm3 from "./pages/test_form_3/TestForm3.js";
import CustomConversion from "./pages/custom_conversion/CustomConversion.js";
import "./App.scss";

const NavBar = ({slEnabled}) => (
  <div className="NavBar">
    <a href="/">Home</a>
    <a href="/test_form_1">Test Form 1</a>
    <a href="/test_form_2">Test Form 2</a>
    <a href="/test_form_3">Test Form 3</a>
    <a href="/custom_conversion">Custom Conversion</a>
    <a href="/page_view_conversion">Page View Conversion</a>
    {slEnabled() ? (
      <span style="margin-left: auto; margin-right: 20px;color: green">Soft Login Enabled</span>
    ) : (
      <span style="margin-left: auto; margin-right: 20px;color: red">Soft Login Disabled</span>
    )}
  </div>
);

const Content = () => {
  const location = useLocation();
  const [queryParams, setQueryParams] = createSignal({});

  // Function to parse query string into an object
  const parseQueryString = () => {
    const params = new URLSearchParams(location.search);
    const queryObject = {};
    for (const [key, value] of params.entries()) {
      queryObject[key] = value;
    }
    setQueryParams(queryObject);
  };
  createEffect(() => {
    parseQueryString();
  });

  const [softLoginEnabled, setSoftLoginEnabled] = createSignal(
    getEnableSoftLogin()
  );

  // Signal for displaying a string value
  const [email, setEmail] = createSignal(getEmailCookie());

  return (
    <>
      <NavBar slEnabled={softLoginEnabled}/>
      <div className="Content">
        <Routes>
          <Route
            end
            path="/"
            component={() => (
              <div class="container">
                <h1>EC Soft Login POC</h1>
                <div class="toggle-container">
                  <label class="toggle">
                    <input
                      type="checkbox"
                      checked={softLoginEnabled()}
                      onChange={(e) => {
                        setEnableSoftLogin(e.target.checked);
                        setSoftLoginEnabled(e.target.checked);
                        if (!e.target.checked) {
                          clearEmailCookie();
                          setEmail(null);
                        }
                      }}
                    />
                    <span class="slider"></span>
                    <span>Enable Soft Login</span>
                  </label>
                </div>

                <p class="email-text">
                  {email() ? (
                    `Email cookie set: ${email()}`
                  ) : (
                    <div>
                      <p>No email cookie set.</p>
                      <p>Fill out a form with Soft Login enabled to set one.</p>
                    </div>
                  )}
                </p>

                <button
                  class="clear-button"
                  onClick={() => {
                    clearEmailCookie();
                    setEmail(null);
                  }}
                >
                  Clear Email Cookie
                </button>
              </div>
            )}
          />
          <Route end path="/test_form_1" component={() => <TestForm1 />} />
          <Route
            end
            path="/lead_gen_page_1"
            component={() => (
              <div>
                <span className="Content-text">
                  {"Thank you for filling out the form!"}
                </span>
                <br />
                <span className="Content-text">
                  {"We will send a follow-up email to "}
                  <span>{queryParams()["email"]}</span>
                </span>
              </div>
            )}
          />
          <Route end path="/test_form_2" component={() => <TestForm2 />} />
          <Route
            end
            path="/lead_gen_page_2"
            component={() => (
              <div>
                <span className="Content-text">
                  {"Thank you for filling out the form!"}
                </span>
                <br />
                <span className="Content-text">
                  {"Please look out for email from "}
                  <span>not_the_users_email@gmail.com</span>
                </span>
              </div>
            )}
          />
          <Route end path="/test_form_3" component={() => <TestForm3 />} />
          <Route
            end
            path="/lead_gen_page_3"
            component={() => (
              <div>
                <span className="Content-text">
                  {"Thank you for filling out the form!"}
                </span>
              </div>
            )}
          />
          <Route
            end
            path="/custom_conversion"
            component={() => <CustomConversion />}
          />
          <Route
            end
            path="/page_view_conversion"
            component={() => (
              <div style="text-align: center;">
                <h1 style="font-size: 24px;margin: 0 10px 20px;">
                  Page view conversion with no email on the page
                </h1>
                <p style="margin-bottom: 5px;">
                  The page view here will trigger a conversion.
                </p>
                <p style="margin-bottom: 5px;">
                  There's no email here for Auto PII to pick up.
                </p>
                <p style="margin-bottom: 5px;">
                  If soft login is enabled and you have filled out the form at
                  least once in the past 90 days,
                </p>
                <p>
                  this page view conversion will fire with the previously saved
                  email
                </p>
              </div>
            )}
          />
        </Routes>
      </div>
    </>
  );
};

const App = () => (
  <Router>
    <Content />
  </Router>
);

const rootElement = document.getElementById("root");
render(() => <App />, root);
